<template>
  <v-stepper v-model="e6" vertical>
    <!-- Agregar selector para el idioma.. -->
    <v-container>
      <v-row>
        <v-col class="d-flex" cols="12" sm="6">
          <v-select
            :items="languagesListObj"
            :label="word(60)"
            v-model="language"
            :item-text="language"
            item-value="value"
          >
          </v-select>
        </v-col>
      </v-row>
    </v-container>

    <v-stepper-step :complete="e6 > 1" step="1">
      <v-icon>
        mdi-account-box-multiple-outline
      </v-icon>
      {{ word(37) }}

      <small>{{ word(27) }}</small>
    </v-stepper-step>

    <v-stepper-content step="1">
      <!--Nombre del prospecto..-->
      <v-container>
        <v-text-field v-model="name" :label="word(21)"></v-text-field>
      </v-container>

      <div class="pa-4 d-flex justify-space-between">
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="e6 = 2" :disabled="!stepOne">
          {{ word(28) }}
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 2" step="2">
      <v-icon>
        mdi-domain
      </v-icon>
      {{ word(22) }}
    </v-stepper-step>

    <v-stepper-content step="2">
      <!-- Propiedades seleccionadas..-->
      <div class="text-center" v-if="propertiesGet.length == 0">
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <v-col cols="12">
        <v-autocomplete
          :label="properties.length < 1 ? word(79) : word(80)"
          v-if="orderProperties.length != 0"
          :items="orderProperties"
          v-model="properties"
          clearable
          chips
          color="#042956"
          prepend-icon="mdi-city"
          :item-text="textSelected"
          return-object
          multiple
          deletable-chips
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  <v-row no-gutters align="center">
                    <span>{{ item.code }}</span>
                    <v-spacer></v-spacer>
                    <v-chip text-color="white" color="primary" small>{{
                      item.floor.name
                    }}</v-chip>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <div class="pa-4 d-flex justify-space-between">
        <v-btn text @click="e6 = 1" fab>
          <v-icon color="primary">
            mdi-arrow-left-thick
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="e6 = 3" :disabled="!stepSecond">
          {{ word(28) }}
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 3" step="3">
      <v-icon>
        mdi-cash
      </v-icon>
      {{ word(30) }}
    </v-stepper-step>

    <v-stepper-content step="3">
      <PriceRule
        :development="developmentGet"
        :properties="properties"
        :priceRules="priceRulesGet"
        :discounts="discountsGet"
        :workWithoutVAT="workWithoutVAT"
        @setPackedPriceRule="value => (packedPriceRule = value)"
        :language="language"
      />

      <div class="pa-4 d-flex justify-space-between">
        <v-btn text @click="e6 = 2" fab>
          <v-icon color="primary">
            mdi-arrow-left-thick
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="e6 = 4" :disabled="!stepThree">
          {{ word(28) }}
        </v-btn>
      </div>
    </v-stepper-content>

    <!-- Nueva sección con el esquema de pago. Muestra los como queria el esquema de los pagos para el pdf...-->
    <v-stepper-step :complete="e6 > 4" step="4">
      <v-icon>
        mdi-cash-multiple
      </v-icon>
      {{ word(25) }}
    </v-stepper-step>

    <v-stepper-content step="4">
      <!--Esquema de pagos.. se le pasa los datos para poder trabajar correctamente..-->
      <PaymentScheme
        :packedPriceRule="packedPriceRule"
        @setpPurchaseScheme="value => (purchaseScheme = value)"
        :language="language"
      />
      <div class="pa-4 d-flex justify-space-between">
        <v-btn text @click="e6 = 3" fab :disabled="!stepFour">
          <v-icon color="primary">
            mdi-arrow-left-thick
          </v-icon>
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="e6 = 5">
          {{ word(28) }}
        </v-btn>
      </div>
    </v-stepper-content>

    <v-stepper-step step="5">
      {{ word(39) }}
    </v-stepper-step>

    <v-stepper-content step="5">
      <!--<v-btn text @click="showData2" disabled>
        Mostrar los datos registrados...
      </v-btn>-->
      <select-contact-broker
        @getOption="getContactBrokerOption"
        :page="'realEstate'"
        :lead="null"
        :language="language"
      />
      <!-- Fin del componente... -->
      <div class="pa-4 d-flex justify-space-between">
        <v-btn text @click="e6 = 4" fab>
          <v-icon color="primary">
            mdi-arrow-left-thick
          </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="#ABEBC6"
          @click="generateQuote()"
          :disabled="!stepSix"
          :loading="loadingPDF"
        >
          <v-icon left>
            mdi-arrow-down-bold-circle
          </v-icon>
          {{ word(38) }}
        </v-btn>
      </div>
    </v-stepper-content>
    <GenerateMappingWithPdf
      ref="generateMappingWithPdf"
      v-if="e6 === 5"
      :dataMap="config.dataMap"
      :properties="properties"
      :packedPriceRule="packedPriceRule"
      :purchaseScheme="purchaseScheme"
      :development="developmentGet"
      :name="name"
      :contactSelected="contactSelected"
      :enabled="stepSix"
      @getLoading="value => (loadingPDF = !value)"
      :language="language"
    />
  </v-stepper>
</template>

<script>
import { word } from "@/pdf/advanced-quoter/multi-language.js";
export default {
  components: {
    GenerateMappingWithPdf: () => import("./GenerateMappingWithPdf.vue"),
    PriceRule: () => import("./AdvancedPriceRule.vue"),
    PaymentScheme: () => import("./PaymentScheme.vue"),
    SelectContactBroker: () =>
      import("@/components/contact-broker/SelectContactBroker.vue")
  },
  name: "QuoteStepper",
  props: {
    propertiesGet: Array,
    developmentGet: Object,
    priceRulesGet: Array,
    discountsGet: Array
  },
  data() {
    return {
      e6: 1,
      lead: {
        name: ""
      },
      //Lenguage
      languagesList: ["spanish", "english"],

      languagesListObj: [
        {
          value: "spanish",
          spanish: "Español",
          english: "Spanish"
        },
        {
          value: "english",
          spanish: "Ingles",
          english: "English"
        }
      ],
      language: "spanish",
      //otros
      name: "",
      contactSelected: null,
      properties: [],
      //Esquema de precios...
      purchaseScheme: [],
      //Paquete de Regla de precios
      packedPriceRule: {},
      development: {},
      broker: {},
      config: {
        dataMap: null
      },
      //Eliminar iva?
      workWithoutVAT: false,
      loadingPDF: false
    };
  },
  created() {
    //this.config.dataMap = this.getDataToMap();
    this.init();
  },
  computed: {
    orderProperties() {
      return this.orderPropertiesMethod(this.propertiesGet);
    },
    stepOne() {
      if (this.name.replace(/ /g, "").length > 2) return true;
      return false;
    },
    stepSecond() {
      if (this.properties.length > 0) return true;
      return false;
    },
    stepThree() {
      if (!this.packedPriceRule.seletedPriceRules) return false;
      return true;
    },
    stepFour() {
      if (this.purchaseScheme.length > 0) return true;
      return false;
    },
    stepSix() {
      if (!this.contactSelected) return false;
      return true;
    }
  },
  methods: {
    word(n) {
      if (this.language == "spanish") return word(n, "spanish");
      else return word(n, "english");
    },
    /*   word(number, l) {
      //english
      return word(number, l);
      //return word(37, "spanish");
    }, */
    init() {
      if (this.developmentGet.unit_types) {
        const unitType = this.developmentGet.unit_types;
        if (unitType == "lots") this.workWithoutVAT = true;
      }

      if (this.$route.params.property) {
        this.properties = [
          this.orderProperties.find(
            element => element._id === this.$route.params.property
          )
        ];
      }
      this.config.dataMap = this.getDataToMap();
    },
    //Ordenar Propiedades en orden alfabetico.
    orderPropertiesMethod(properties) {
      return properties.sort((a, b) => a.code - b.code);
    },
    generateQuote() {
      this.loadingPDF = true;
      setTimeout(this.$refs.generateMappingWithPdf.generateQuote(), 500);
    },
    //Datos del broker Seleccionado..
    getContactBrokerOption(option) {
      if (option) {
        this.contactSelected = option;
      }
    },
    textSelected(item) {
      return item.code + " - " + item.floor.name;
    },
    getDataToMap() {
      //Verificar que haya imagen frontal del desarrollo
      if (!this.developmentGet.media) return null;
      if (!this.developmentGet.media.structure) return null;
      if (!this.developmentGet.media.structure.src) return null;
      ///Coordenadas de los niveles...
      if (!this.developmentGet.miscellaneous) return null;
      if (!this.developmentGet.miscellaneous.mapping_coords_structure)
        return null;
      ///Imagenes de los niveles...
      if (!this.developmentGet.development_structure) return null;
      if (!this.developmentGet.development_structure.floors) return null;
      //Juntar las imagenes con las coordenadas...
      var a1 = this.developmentGet.miscellaneous.mapping_coords_structure;
      var a2 = this.developmentGet.development_structure.floors;
      //Merge
      const merge = a1.map(t1 => ({
        ...t1,
        ...a2.find(t2 => t2.name.toUpperCase() === t1.name.toUpperCase())
      }));
      //Si todo sale bien, se retorna
      return {
        coords_and_img: merge,
        img_front: this.developmentGet.media.structure.src
      };
    },
    showData() {
      this.config.dataMap = this.getDataToMap();
      this.findPropertiesMapping();
    }
  }
};
</script>
